export default {
  props: {
    companies: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      visiblePointIndex: 0
    }
  },
  computed: {
    numberOfPoints() {
      return [...this.$refs.images.childNodes].filter((node) => {
        return node.classList !== undefined
      }).length
    }
  },
  methods: {
    setActiveIndex(index) {
      this.visiblePointIndex = index
    },
    getClasses(index) {
      return {
        'landing__images__image--active': index === this.visiblePointIndex
      }
    },
    getStepClasses(index) {
      return {
        'how-it-works__steps__step--active': index === this.visiblePointIndex
      }
    },
    goToSimulator() {
      const options = {
        name: 'simulacao-seguro-automovel-simulador'
      }
      this.$router.push(this.localePath(options))
    }
  }
}
